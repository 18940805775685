import { Helmet } from "react-helmet";

// components
import Navbar from "../components/Navbar";
import Heading from "../components/Heading";

// css
import "./Home.css";

function Riabilitazione() {
    return (
        <div className="Riabilitazione">
            <Helmet>
                <title>Riabilitazione - My App</title>
            </Helmet>
            <Navbar />
            <Heading pageName={"Riabilitazione"} />
        </div>
    );
}

export default Riabilitazione;
