import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/imgs/logo.png";

// css
import "./Navbar.css";

function Navbar() {
    const location = useLocation();

    const [clicked, setClicked] = useState(false);

    function handleClick() {
        setClicked(!clicked);
    }

    return (
        <div>
            <nav>
                <Link to="/">
                    <div className="logo_container">
                        <div className="img_container">
                            <img src={logo}></img>
                        </div>
                        <div className="text_container">
                            <div id="nome">Simonetta Minero Re</div>
                            <div id="ruolo">FISIOTERAPISTA E OSTEOPATA</div>
                        </div>
                    </div>
                </Link>

                <div>
                    <ul id="Navbar" className={clicked ? "active" : ""}>
                        <li>
                            <Link to="/" className={location.pathname === "/" ? "current" : ""}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/riabilitazione"
                                className={location.pathname === "/riabilitazione" ? "current" : ""}
                            >
                                Riabilitazione
                            </Link>
                        </li>
                        <li>
                            <Link to="/corsi" className={location.pathname === "/corsi" ? "current" : ""}>
                                Corsi
                            </Link>
                        </li>
                        <li>
                            <Link to="/formazione" className={location.pathname === "/formazione" ? "current" : ""}>
                                Formazione
                            </Link>
                        </li>
                        <li>
                            <Link to="/contatti" className={location.pathname === "/contatti" ? "current" : ""}>
                                Contatti
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="mobile" onClick={handleClick}>
                    <i id="hamburger" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
