import { Helmet } from "react-helmet";

// components
import Navbar from "../components/Navbar";
import Heading from "../components/Heading";

// css
import "./Home.css";

function Corsi() {
    return (
        <div className="Corsi">
            <Helmet>
                <title>Corsi - My App</title>
            </Helmet>
            <Navbar />
            <Heading pageName={"Corsi"} />
        </div>
    );
}

export default Corsi;
