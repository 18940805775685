import PropTypes from "prop-types";

function Heading(props) {
    return (
        <div
            style={{
                textAlign: "center",
                marginTop: "140px",
            }}
        >
            <h1 style={{ marginBottom: "40px" }}> {props.pageName}</h1>
            <p> Esplora il sito dal menu che vedi sopra.</p>
        </div>
    );
}

Heading.propTypes = {
    pageName: PropTypes.string,
};

export default Heading;
