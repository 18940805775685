import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// CSS
import "./index.css";

// screens
import Home from "./screens/Home";
import Riabilitazione from "./screens/Riabilitazione";
import Corsi from "./screens/Corsi";
import Formazione from "./screens/Formazione";
import Contatti from "./screens/Contatti";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/riabilitazione" element={<Riabilitazione />} />
                <Route path="/corsi" element={<Corsi />} />
                <Route path="/formazione" element={<Formazione />} />
                <Route path="/contatti" element={<Contatti />} />
                <Route path="/*" element={<Home />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
